import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { processImageUrl } from '../../utils/image';
import Image from '../Image/Image';
import RawHtml from '../RawHtml/RawHtml';
import useStyles from './BlogArticleContent.styles';
import { useLoaded } from '../MainContent/useComponentLoading';
const BlogArticleContent = (props) => {
    const { title, message, images, imagePosition } = props;
    const { classes } = useStyles();
    const imageTopPosition = imagePosition === 'TOP' ? classes.imagePositionTop : classes.imagePositionBottom;
    const multipleImages = ((images === null || images === void 0 ? void 0 : images.length) || 0) > 1;
    const sizes = [
        {
            breakpoint: 'sm',
            query: 'max-width',
            width: '100vw',
        },
        {
            breakpoint: 'xl',
            query: 'max-width',
            width: multipleImages ? '50vw' : '100vw',
        },
        {
            width: multipleImages ? '960px' : '1608px',
        },
    ];
    const imageWidths = [240, 384, 480, 960, 1280, 1608];
    const imageTemplate = ['$poi-square$'];
    const imageAttributes = {
        w: 1608,
        aspect: multipleImages ? '1:1' : '2:1',
    };
    useLoaded(props);
    return (_jsxs("div", { className: clsx(classes.container, imageTopPosition !== null && imageTopPosition !== void 0 ? imageTopPosition : undefined), children: [title && _jsx("h2", { className: classes.title, children: title }), _jsx(RawHtml, { className: classes.message, content: message }), !!(images === null || images === void 0 ? void 0 : images.length) && (_jsx("div", { className: clsx(classes.images, multipleImages && classes.multipleImages), children: images.map((image, index) => (_jsx("div", { className: classes.imageContainer, children: _jsx(Image, { className: clsx(classes.image), imageUrl: processImageUrl(image.url, imageAttributes, imageTemplate), altText: image.altText || 'Blog article', imageWidths: imageWidths, sizes: sizes, loading: "lazy" }) }, `blogarticle_${index}`))) }))] }));
};
export default BlogArticleContent;
