import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    container: {
        '& h1': Object.assign(Object.assign(Object.assign({ textAlign: theme.isFlyingFlowers ? 'center' : 'unset', color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.xl_regular), theme.typographySpacing.xl_regular_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.xxxxl : theme.typography.xxxl)), (theme.isFlyingFlowers ? theme.typographySpacing.xxxxl_sm : theme.typographySpacing.xxxl_sm)) }),
    },
    reminderMessage: {
        '& p': Object.assign(Object.assign(Object.assign({}, theme.typography.medium_regular), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_sm : theme.typographySpacing.medium_regular_sm)), { textAlign: theme.isFlyingFlowers ? 'center' : 'unset', color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), (theme.isFlyingFlowers
                ? theme.typographySpacing.large_regular_sm
                : theme.typographySpacing.large_regular_lg)), '&:first-of-type': {
                marginBottom: spacing.large,
            } }),
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: theme.isFlyingFlowers ? 'center' : 'flex-start',
        marginBottom: '50px',
        textAlign: 'center',
    },
    addOccasionButton: {
        '& button': {
            '&.MuiFab-root': {
                '&:focus': {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
                },
            },
        },
    },
    highlight: {
        color: theme.statusColors.warning.dark,
    },
    alertMessage: {
        marginBottom: spacing.large,
        alignItems: 'center',
    },
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg), { textAlign: theme.isFlyingFlowers ? 'center' : 'left', [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_bold), theme.typographySpacing.xl_bold_lg), { textAlign: 'left' }) }),
    remindersContainer: {},
}));
export default useStyles;
