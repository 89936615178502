import { makeStyles } from 'tss-react/mui';
import { spacing, colors } from '../../styles/jss/variables';
import { rawContentBlockStyles } from './../../styles/jss/contentBlock';
const useStyles = makeStyles()((theme, _params, classes) => ({
    headingSection: Object.assign({}, rawContentBlockStyles(theme).headingSection),
    title: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none), { marginBottom: spacing.medium, color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), theme.typographySpacing.none), { marginBottom: spacing.large }) }),
    carouselTitle: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg), { [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_lg) }),
    message: {
        color: theme.isFlyingFlowers ? colors.black[700] : undefined,
    },
    carouselMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), { '& p': {
            '&:last-child': {
                marginBottom: 0,
            },
        } }),
    productGallery: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    carouselProductGallery: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    linkContainer: {
        margin: `${spacing.medium} 0 `,
    },
    link: {
        '&:not(:last-child)': {
            marginRight: spacing.small,
        },
    },
    deliveryPass: {
        [`& .${classes.headingSection}`]: {
            textAlign: 'left',
            marginBottom: spacing.large,
            [theme.breakpoints.up('md')]: {
                marginBottom: spacing.medium,
            },
        },
        '& h2': Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.medium, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_bold), { marginBottom: spacing.medium, fontSize: '1.25rem !important' }) }),
        [`& .${classes.message}`]: Object.assign(Object.assign({}, theme.typography.small_regular), { '& p': {
                [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular),
            } }),
    },
}));
export default useStyles;
