import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
export const MAX_CARD_WIDTH = 384;
const useStyles = makeStyles()((theme, _params, classes) => ({
    productCard: {
        width: '100%',
        maxWidth: `${MAX_CARD_WIDTH}px`,
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '& .react-multi-carousel-dot-list': {
            position: 'initial',
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    cardClickable: {
        '&:hover': {
            backgroundColor: colors.grey.grey1,
            cursor: 'pointer',
        },
        [`&.${classes.productCardBorder}:active`]: {
            boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.12), 0px 8px 20px rgba(0, 0, 0, 0.04)',
        },
        [`&.${classes.productCardBorder}:focus`]: {
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        },
    },
    productCardBorder: {},
    priceContainer: {
        marginBottom: spacing.medium,
    },
    deliveryPassPriceContainer: {
        marginBottom: spacing.small,
    },
    price: Object.assign(Object.assign({}, theme.typography.medium_bold), { paddingRight: spacing.xs, color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    deliveryPassPrice: Object.assign(Object.assign({}, theme.typography.small_bold), { paddingRight: spacing.xs }),
    promoText: Object.assign(Object.assign({}, theme.typography.medium_bold), { color: colors.secondary.lavender }),
    deliveryPassPromoText: Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.grey.grey6, marginBottom: spacing.medium }),
    productName: Object.assign(Object.assign(Object.assign({ display: 'block' }, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_regular), { display: 'flex', flexGrow: 1 }) }),
    deliveryPassProductName: Object.assign(Object.assign({ display: 'block' }, theme.typography.medium_bold), theme.typographySpacing.medium_bold_lg),
    textContent: {
        padding: spacing.medium,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        [`& .${classes.priceContainer}`]: {
            marginBottom: '0px',
        },
    },
    icon: {
        color: `${colors.grey.grey6} !important`,
        marginTop: '4px',
        marginRight: spacing.small,
        '& .material-icons': {
            fontSize: '22px',
        },
    },
    container: {
        display: 'flex',
        marginBottom: '12px',
    },
    variantText: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6 }, (theme.isFlyingFlowers ? theme.typography.xxs_bold : theme.typography.small_bold)), theme.typographySpacing.xxs_bold_sm), { lineHeight: theme.isFlyingFlowers ? '1.25rem' : undefined, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg) }),
    topSectionContainer: {
        flexGrow: 1,
    },
    bottomSectionContainer: {},
    textContentSimple: {
        padding: `11px ${spacing.xs}`,
        [`& .${classes.priceContainer}`]: {
            marginBottom: '0px',
        },
        [`& .${classes.productName}`]: Object.assign(Object.assign({}, theme.typography.small_regular), { marginBottom: '0px', color: colors.grey.grey6 }),
        [`& .${classes.price}`]: Object.assign(Object.assign({}, theme.typography.medium_bold), { marginBottom: '0px', color: colors.supplementary.black }),
    },
    deliveryPassImage: {
        width: '100%',
        padding: spacing.medium,
        paddingBottom: 0,
        '& div': {
            height: '108px',
            width: '108px',
        },
        '& img': {
            width: '108px',
        },
    },
    linkWrapper: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
    },
    iconClass: {
        color: colors.black[700],
    },
    wasPrice: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.small_bold_lg : undefined)), { color: theme.isFlyingFlowers ? colors.wildExotic[400] : undefined, marginBottom: spacing.small }),
    imageContainer: {},
    disableProductCard: {
        [`& img, & .${classes.textContent}`]: {
            opacity: 0.3,
        },
    },
    productSuggestion: {
        cursor: 'pointer',
    },
    allWasPrice: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.primary.gold5, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.none) }),
    searchCardWasPrice: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg),
    strikeThroughPrice: {
        textDecoration: 'line-through',
        color: colors.supplementary.black,
    },
    productCardUKWasPrice: {
        marginBottom: 0,
    },
}));
export default useStyles;
