import { makeStyles } from 'tss-react/mui';
import { spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: '50%',
        '&:not(:last-child)': {
            [theme.breakpoints.down('xl')]: {
                marginBottom: spacing.small,
            },
            [theme.breakpoints.up('md')]: {
                marginRight: spacing.small,
            },
        },
    },
    promoImage: {
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            flexBasis: '25%',
        },
        '& span': {
            position: 'relative', // Need to get back: !important',
            height: '100% !important',
            width: '100% !important',
        },
        '&:not(:last-child)': {
            [theme.breakpoints.down('lg')]: {
                marginBottom: theme.isFlyingFlowers ? spacing.xs : 0,
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.isFlyingFlowers ? spacing.xs : 0,
            },
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: theme.isFlyingFlowers ? 'center' : 'bottom',
            display: 'block',
            minHeight: '250px',
            [theme.breakpoints.up('md')]: {
                objectPosition: 'bottom',
                minHeight: 'initial',
            },
        },
        '& button, & picture': {
            display: 'block',
            height: '100%',
            width: '100%',
        },
    },
    heading: {
        '& h2, & h3, & h4': Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_bold : theme.typography.small_bold)), (theme.isFlyingFlowers ? theme.typographySpacing.medium_bold_sm : theme.typographySpacing.small_bold_sm)),
    },
    promoContent: {
        width: '100%',
        flexGrow: 1,
        padding: theme.isFlyingFlowers ? spacing.large : spacing.medium,
        [theme.breakpoints.up('sm')]: {
            padding: theme.isFlyingFlowers ? spacing.xl : spacing.large,
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.isFlyingFlowers ? spacing.xxl : spacing.large,
        },
        '& :last-child': {
            marginBottom: '0',
        },
        '& p': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
        '& ul': {
            paddingInlineStart: '1em',
            marginBottom: '1em',
        },
        '& ul > li': Object.assign(Object.assign({}, theme.typography.small_regular), (theme.isFlyingFlowers ? theme.typographySpacing.none : theme.typographySpacing.small_regular_sm)),
        '& a': Object.assign({}, theme.typography.small_regular),
    },
    searchResultsTitle: {},
    title: Object.assign(Object.assign({}, theme.typography.xl_bold), { marginBottom: spacing.xs, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), { marginBottom: spacing.small }), [`&.${classes.searchResultsTitle}`]: Object.assign(Object.assign(Object.assign({}, theme.typography.xl_regular), (theme.isFlyingFlowers && theme.typographySpacing.xl_regular_sm)), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxxl), (theme.isFlyingFlowers && theme.typographySpacing.xxxl_lg)) }) }),
    message: Object.assign(Object.assign({}, theme.typography.large_regular), { marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xl_regular), { margin: '36px 0 28px 0' }) }),
    description: {
        '&, & p': Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, (theme.isFlyingFlowers ? theme.typography.medium_regular : theme.typography.large_regular)), '&:last-child': {
                marginBottom: 0,
            } }),
        marginBottom: spacing.medium,
        [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        },
    },
    linksTitle: Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
    link: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.medium_regular) }),
    guidedNavigationContainer: {
        marginBottom: '40px',
    },
    positionBottom: {
        alignSelf: 'flex-end ',
    },
    positionTop: {
        alignSelf: 'flex-start',
    },
    positionCenter: {
        alignSelf: 'center',
    },
    bodyContentContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            flexBasis: '25%',
        },
    },
    contentWithoutImage: {
        padding: spacing.medium,
        [theme.breakpoints.up('sm')]: {
            padding: spacing.large,
        },
        [theme.breakpoints.up('xl')]: {
            padding: spacing.xl,
        },
    },
}));
export default useStyles;
