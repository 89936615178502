import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme) => ({
    summary: {},
    productName: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), { color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.large_regular), theme.typographySpacing.large_regular_sm) }),
    pricing: Object.assign(Object.assign({ verticalAlign: 'baseline' }, theme.typographySpacing.small_bold_lg), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typographySpacing.xl_bold_sm) }),
    price: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black }, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.xl_bold) }),
    promoText: Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_bold) }),
    wasPrice: Object.assign(Object.assign(Object.assign({}, (theme.isFlyingFlowers ? theme.typography.small_bold : undefined)), (theme.isFlyingFlowers ? theme.typographySpacing.small_bold_lg : undefined)), { color: theme.isFlyingFlowers ? colors.wildExotic[400] : undefined, marginBottom: spacing.small }),
    secondaryBadge: {
        margin: `${spacing.xs} 0 ${spacing.small} 0`,
        [theme.breakpoints.up('md')]: {
            margin: `${spacing.small} 0`,
        },
    },
    deliveryMessageText: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { paddingTop: 0 }),
    container: {
        alignItems: 'start',
        whiteSpace: 'pre-wrap',
        paddingLeft: '3px',
        marginBottom: spacing.xs,
        display: 'flex',
        '&:last-child': {
            marginBottom: spacing.large,
        },
    },
    icon: {
        marginTop: '0px',
        marginRight: '10px',
        height: '20px',
        width: '20px',
        marginLeft: '3px',
    },
    allWasPrice: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), { color: colors.primary.gold5, [theme.breakpoints.up('md')]: {
            marginBottom: spacing.medium,
        } }),
    strikeThroughPrice: {
        textDecoration: 'line-through',
        color: colors.supplementary.black,
    },
    noMargin: {
        marginBottom: theme.isFlyingFlowers ? spacing.medium : 0,
    },
}));
export default useStyles;
